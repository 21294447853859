import React, { useState } from 'react';
import { Switch, Route, Link, Redirect, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import routes from '../app-config';
import { usePageViews } from '../usePageViews';
import Icon from './Icon';

const NavWrapper = styled.nav`
    width: 100%;
    height: 60px;
    font-size: 18px;
    border-bottom: 3px solid;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    border-image: radial-gradient(circle, #854B23, transparent) 1;
`;

const MobileNavWrapper = styled.div`
    align-items: center;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    border-bottom: 3px solid;
    border-image: radial-gradient(circle, #854B23, transparent) 1;
`;

const MobileNavIconWrapper = styled.div`
    height: 17px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    margin: 10px 0;
`;

const ItemsContainer = styled(animated.div)`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    overflow: hidden;
`;

const StyledIcon = styled(Icon)`
    -webkit-tap-highlight-color: transparent;
`;

const NavItem = styled.div`
    margin: 10px 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    a {
        color: ${p => p.selected ? '#854B23' : 'black'};
        text-decoration: none;
        &:hover {
            opacity: ${p => p.selected ? 1 : 0.7};
        }
    }
`;

const Router = ({ isMobile }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    usePageViews(location);
    const props = useSpring({
        to: {
            height: isMenuOpen ? `100%` : `0px`,
        },
        from: {
            height: isMenuOpen ? `100%` : `0px`,
        }
    });
    return (
        <>
            {isMobile ?
                <MobileNavWrapper>
                    <MobileNavIconWrapper>
                        <StyledIcon
                            type={isMenuOpen ? 'times' : 'bars'}
                            onClick={() => setIsMenuOpen(state => !state)}
                            color='#854B23'
                        />
                    </MobileNavIconWrapper>
                    <ItemsContainer style={props}>
                        {
                            routes.filter(r => !r.hidden).map((r, i) =>
                                <NavItem key={i} selected={r.url === location.pathname} onClick={() => setIsMenuOpen(false)}>
                                    <Link to={r.url}>{r.title}</Link>
                                </NavItem>
                            )
                        }
                    </ItemsContainer>
                </MobileNavWrapper> :
                <NavWrapper>
                    {
                        routes.filter(r => !r.hidden).map((r, i) =>
                            <NavItem key={i} selected={r.url === location.pathname}>
                                <Link to={r.url}>{r.title}</Link>
                            </NavItem>
                        )
                    }
                </NavWrapper>
            }
            <Switch>
                {
                    routes.map((r, i) => <Route exact path={r.url} component={r.component} key={i} />)
                }
                <Redirect to={routes[0].url} />
            </Switch>
        </>
    )
}

export default Router;