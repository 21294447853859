import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from './Icon';

const FooterContainer = styled.footer`
    min-height: 75px;
    width: 100%;
    background-color: transparent;
    color: black;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center
`;

const Icons = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-flow: row nowrap;
    > i {
        margin: 5px;
    }
`;

const Info = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 2px;
`;

const StyledLink = styled(Link)`
    margin: 5px;
    height: 21px;
    color: black; !important;
`;

const Copyright = styled.p`
    margin: 5px;
    height: 21px;
`;

const Footer = ({ reference }) =>
    <FooterContainer ref={reference}>
        <Icons>
            <Icon
                theme='fab'
                type='facebook'
                pointer={true}
                size='2x'
                onClick={() => window.open('https://www.facebook.com/florexinterieur', '_blank')}
                color='black'
            />
        </Icons>
        <Info>
            <Copyright>&copy; BV Florex Interieur 2021</Copyright> -
            <StyledLink to={'/privacy'}>Privacy policy</StyledLink> -
            <StyledLink to={'/cookie'}>Cookie policy</StyledLink> -
            <StyledLink to={'/disclaimer'}>Disclaimer</StyledLink>
        </Info>
    </FooterContainer>;

export default Footer;