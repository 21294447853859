import React from 'react';
import styled from 'styled-components';
import '../index.css';

const FAIcon = styled.i`
    color: ${p => p.color};
    cursor: ${p => p.pointer ? 'pointer' : 'default'};
    ${p => p.width && `width: ${p.width}px`};
    text-align: center;
`

const Icon = ({ type, theme = 'fas', onClick, pointer = false, color='white', size = 'lg', width }) =>
    <FAIcon
        className={`${theme} fa-${type} fa-${size}`}
        pointer={pointer}
        onClick={onClick}
        color={color}
        width={width}
    ></FAIcon>

export default Icon