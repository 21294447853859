import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from './Container';
import keuken from '../images/keuken_home.jpg';
import logo from '../images/logo.png';
import portfolio from '../images/portfolio.jpg';
import { useWindowSize } from '../useWindowSize';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import ReactImageGallery from 'react-image-gallery';

const secondColor = '#a9998f'

const TextContainer = styled.div`
    width: ${p => p.vertical ? 'auto' : '60%'};
    ${p => p.vertical && 'margin-top: -4px;'}
    z-index: 1;
    padding: 15px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
`;

const Title = styled.p`
    font-size: 26px;
    font-weight: bold;
    color: #854B23;
`

const ImageContainer = styled.div`  
    width: ${p => p.vertical ? '100%' : '40%'};
    img {
        width: 100%;
        height: 100%;
    }
`;

const ImageGalleryContainer = styled(ImageContainer)`  
    height: 500px !important;
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        max-height: 640px;
    }
    ${p => p.vertical && `
        display: flex;
        justify-content: center;
    `}
`;

const Header = styled.div`  
    margin-left: -16px;
    display: flex;
    width: calc(100% + 48px);
    flex-flow: row nowrap;
    justify-content: flex-start;
    img {
        width: 100%;
        height: 100%;
    }
    position: relative;
`;

const HeaderTitle = styled.p`
    top: 30%;
    font-weight: bold;
    color: brown;
    left: 30%;
    width: 40%;
    position: absolute;
    z-index: 3;
`;

const HeaderLeft = styled.div`
    width: 55%;
    z-index: 2;
    :after {
        position: absolute;
        top: 0;
        left: calc(50% - 5px);
        height: 100%;
        width: 10%;
        background-color: ${secondColor};
        content: '';
        transform: skew(-16deg);
        border-left: 8px solid white;
    }
`;

const HeaderRight = styled.div`
    width: 45%;
    background-color: ${secondColor};
    z-index: 1;
`;

const Lint = styled.div`
    margin-top: 20px;
    display: flex;
    flex-flow: ${p => p.vertical ? 'column nowrap' : 'row nowrap'};
    ${p => p.vertical && !p.isEven && 'flex-direction: column-reverse'};
    align-items: stretch;
    justify-content: center;
    ${p => !p.vertical && 'margin-left: -16px;'}
    width: calc(100% + 48px);
    height: auto;
    overflow: hidden;
    ${p => p.vertical && 'margin-bottom: 30px;'}
    background-color: transparent;
`;

const StyledButton = styled(Button)`
    margin-top: 20px !important;
    background-color: #854B23 !important;
    color: white !important;
`;

const Text = styled.div`
    padding: 8px;
    max-width: 80%;
`;

const Home = () => {
    const history = useHistory();
    const [width] = useWindowSize();
    const [images, setImages] = useState([]);

    const importAll = (r) => {
        r.keys().forEach((item, index) => {
            setImages(state => [
                ...state,
                {
                    original: r(item).default,
                }
            ]);
        });
    }

    useEffect(() => {
        importAll(require.context('../images/home', false, /\.(png|jpe?g|svg)$/))
    }, [])

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <img src={logo} alt='Florex Interieur' />
                </HeaderTitle>
                <HeaderLeft>
                    <img src={portfolio} alt='Florex Interieur' />
                </HeaderLeft>
                <HeaderRight />
            </Header>
            <Lint vertical={width < 1200}>
                <ImageContainer vertical={width < 1200}>
                    <img src={keuken} alt='Florex Interieur' />
                </ImageContainer>
                <TextContainer vertical={width < 1200}>
                    <Text>
                        <Title>Bij Florex interieur bent u aan het juiste adres !</Title>
                        U bouwde of verbouwde net uw woning en u wil die nu praktisch en ook volledig naar uw smaak inrichten? <br />
                        U heeft een interieur dat aan vernieuwing toe is ?<br />
                        Wij staan garant voor kwalitatief maatwerk. We luisteren eerst nauwgezet naar uw verlangens, wensen, noden… Dan gaan we zelf of indien u dat wenst in samenwerking met een ervaren architect / interieurarchitect vorm geven aan uw dromen. <br />
                        Alles wordt in eigen atelier vervaardigd met de grootste aandacht voor perfecte afwerking en degelijke kwaliteit.<br />
                        Van keuken tot badkamer, van dressing tot slaapkamer, van woonkamer tot bureel…
                        U bent steeds welkom voor een vrijblijvende offerte !<br />
                    </Text>
                    <StyledButton onClick={() => history.push('/contact')}>Contact</StyledButton>
                </TextContainer>
            </Lint>
            <Lint vertical={width < 1200} isEven={true}>
                <TextContainer vertical={width < 1200}>
                    <Text>
                        Alles wordt in eigen atelier vervaardigd met de grootste aandacht voor perfecte afwerking en degelijke kwaliteit.<br />
                        Florex interieur is een klein familiebedrijf met een uitmuntende reputatie. Al drie generaties lang wordt de kunst van het ontwerpen, het ambacht van vervaardigen en het vertrouwen opbouwen met de klant onderling aan elkaar doorgegeven, met als doel kwalitatief maatwerk te leveren.<br /><br />
                        Kijk gerust eens naar ons werk in de gallerij.
                    </Text>
                    <StyledButton onClick={() => history.push('/gallerij')}>Gallerij</StyledButton>
                </TextContainer>
                <ImageGalleryContainer vertical={width < 1200}>
                    <ReactImageGallery
                        items={images}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        autoPlay={true}
                        showPlayButton={false}
                    />
                </ImageGalleryContainer>
            </Lint>
        </Container>
    )
}
export default Home;

// TODO: MOBILE, Facbook landing page, textcontainer