import React from 'react';
import styled from 'styled-components';
import { Card, CardContent } from '@material-ui/core';
import Icon from './Icon';
import { useWindowSize } from '../useWindowSize';

const ContactInfo = styled.div`
    width: 100%;
    margin-top: ${p => p.marginTop || p.width < 750 ? '5%' : '16px'};
    margin-bottom: 25px;
`;

const IconText = styled.span`
    margin-left: 10px;
    color: #854B23;
`;

const IconLink = styled.a`
    margin-left: 10px;
    color: #854B23;
`;

const MailText = styled.a`
    margin-left: 10px;
    color: #854B23 !important;
`;

const IconWithText = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    overflow-wrap: anywhere;
`;

const StyledCard = styled(Card)`
    width: 100%;
    background-color: white !important;
    border: 1px solid #854B23;
`;

const ContactCard = ({ marginTop }) => {
    const [width] = useWindowSize();

    return (
        <ContactInfo width={width} marginTop={marginTop}>
            <StyledCard>
                <CardContent>
                    <IconWithText>
                        <Icon type='user' color='#854B23' size='sm' width={14} />
                        <IconText>BV Florex Interieur</IconText>
                    </IconWithText>
                    <IconWithText>
                        <Icon type='envelope' color='#854B23' size='sm' width={14} />
                        <MailText href='mailto:koen.deloof@telenet.be'>koen.deloof@telenet.be</MailText>
                    </IconWithText>
                    <IconWithText>
                        <Icon type='map-marker-alt' color='#854B23' size='sm' width={14} />
                        <IconLink href='https://www.google.com/maps/place/Florex+Interieur/@51.0056584,3.3494032,15z/data=!4m5!3m4!1s0x0:0xffa451482cf31c33!8m2!3d51.0056118!4d3.3492722' target="_blank">Lammersakker 12, 8700 Tielt</IconLink>
                    </IconWithText>
                    <IconWithText>
                        <Icon type='mobile-alt' color='#854B23' size='sm' width={14} />
                        <IconText>0475 40 59 75</IconText>
                    </IconWithText>
                    <IconWithText>
                        <Icon type='briefcase' color='#854B23' size='sm' width={14} />
                        <IconText>BE 0843.737.078</IconText>
                    </IconWithText>
                </CardContent>
            </StyledCard>
        </ContactInfo>
    )
}

export default ContactCard;