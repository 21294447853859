import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import styled from 'styled-components';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import { initGA } from './helper';
import { useWindowSize } from './useWindowSize';
import ScrollToTop from './components/ScrollToTop';
import './App.css';

const ContentContainer = styled.div`
    min-height: ${p => `calc(${p.height ? `${p.height}px` : '100vh'} - ${p.width > 540 ? 85 : p.width > 340 ? 120 : 150}px)`};
`;

const StyledLink = styled(Link)`
    color: white;
`;
const App = () => {
  const [width, height] = useWindowSize();
  const footerRef = useRef(null)

  const handleCookieAccept = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  }

  const handleCookieDecline = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  }

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleCookieAccept();
    }
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ContentContainer height={height} width={width}>
        <Navigation isMobile={width < 750} />
      </ContentContainer>
      <Footer reference={footerRef} />
      <CookieConsent
        enableDeclineButton={true}
        // for development
        debug={false}
        buttonText='Ja, ik aanvaard de voorwaarden.'
        declineButtonText='Nee, liever niet.'
        onAccept={handleCookieAccept}
        onDecline={handleCookieDecline}
        expires={7}
        flipButtons={true}
        buttonStyle={{
          backgroundColor: '#854B23',
          borderRadius: '5px',
          color: 'white'
        }}
        declineButtonStyle={{
          backgroundColor: 'transparent',
          textDecoration: 'underline',
          color: 'white'
        }}
        style={{
          alignItems: 'center',
        }}
      >
        Om je een optimale surfervaring te bieden, maken wij gebruik van analytische cookies. Daarvoor vragen we je toestemming. Lees het <StyledLink to={'/cookies'}>cookiebeleid</StyledLink> en <StyledLink to={'/privacy'}>privacyverklaring</StyledLink> voor meer info.
      </CookieConsent>
    </Router>
  );
}

export default App;
