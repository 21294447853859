import React, { useEffect, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import styled from 'styled-components';
import Icon from './Icon';
import logo from '../images/logo.png';
import ContactCard from './ContactCard';
import { useWindowSize } from '../useWindowSize';
import Container from './Container';

const FormWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    ${p => (p.width < 750) && 'justify-content: center'};
    width: 100%;
    justify-content: space-around;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    background-color: transparent !important;
    margin: 40px 0;
`;

const SendText = styled.span`
    margin-right: 5px;
`;

const StyledForm = styled.form`
    width: ${p => p.width < 750 ? '90%' : '40%'};
`;

const SendButton = styled(Button)`
    background-color: #854B23 !important;
`;

const ContactColumn = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: ${p => p.width < 750 ? '90%' : p.width < 900 ? '35% ' : p.width < 1250 ? '25%' : '20%'};
`;

const Notification = styled.div`
    background-color: ${p => p.type === 'success' ? 'rgba(44, 212, 15, 0.4)' : 'rgba(247, 0, 0, 0.4)'}};
    padding: 5px;
    color: white;
    border-radius: 5px;
    border: 2px solid ${p => p.type === 'success' ? 'rgb(44, 212, 15)' : 'rgb(247, 0, 0)'};
    width: ${p => p.width < 750 ? '90%' : '60%'};
    margin-top: 20px;
`;

const Image = styled.img`
    width: ${p => p.width < 750 ? '90%' : '50%'};
    border-radius: 5px;
`;

const Contact = () => {
    const [formValues, setFormValues] = useState({
        naam: '',
        email: '',
        telefoon: '',
        bericht: ''
    });
    const [mailSuccess, setMailSuccess] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [width] = useWindowSize();

    useEffect(() => {
        setMailSuccess(false);
        setMailError(false);
        return () => {
            setMailSuccess(false);
            setMailError(false);
        }
    }, [])

    const handleFormChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        })
    }

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&')
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': event.target.getAttribute('name'),
                ...formValues,
            })
        })
            .then(() => {
                setMailError(false)
                setMailSuccess(true);
                event.target.naam.value = '';
                event.target.email.value = '';
                event.target.telefoon.value = '';
                event.target.bericht.value = '';
                setFormValues({
                    naam: '',
                    email: '',
                    telefoon: '',
                    bericht: ''
                })
            })
            .catch(() => {
                // Hier error als parameter en loggen om te debuggen
                setMailError(true)
                setMailSuccess(false);
            })
    }
    return (
        <Container>
            {mailSuccess &&
                <Notification type='success' width={width}>
                    Uw bericht werd goed ontvangen. We zullen u zo snel mogelijk contacteren.
                </Notification>
            }
            {mailError &&
                <Notification type='error' width={width}>
                    Er is iets misgegaan. U kan het later nog eens proberen of u kan ons rechtstreeks contacteren via de contactgegevens op deze pagina.
                </Notification>
            }
            <FormWrapper width={width}>
                <ImageContainer width={width}>
                    <Image src={logo} alt='Florex Interieur' width={width} />
                </ImageContainer>
                <StyledForm
                    name='contact'
                    method='POST'
                    onSubmit={handleSubmit}
                    width={width}
                >
                    {/* You still need to add the hidden input with the form name to your JSX form */}
                    <input type='hidden' name='form-name' value='contact' />
                    <p>
                        <TextField
                            label='Naam'
                            name='naam'
                            variant='filled'
                            required={true}
                            fullWidth={true}
                            onChange={handleFormChange}
                        />
                    </p>
                    <p>
                        <TextField
                            label='Email'
                            name='email'
                            variant='filled'
                            required={true}
                            fullWidth={true}
                            onChange={handleFormChange}
                        />
                    </p>
                    <p>
                        <TextField
                            label='Telefoon'
                            name='telefoon'
                            variant='filled'
                            fullWidth={true}
                            onChange={handleFormChange}
                        />
                    </p>
                    <p>
                        <TextField
                            label='Bericht'
                            name='bericht'
                            rows={4}
                            multiline={true}
                            variant='filled'
                            required={true}
                            fullWidth={true}
                            onChange={handleFormChange}
                        />
                    </p>
                    <ButtonContainer>
                        <SendButton
                            variant='contained'
                            color='primary'
                            type='submit'
                        >
                            <SendText>Verzenden</SendText>
                            <Icon type='paper-plane' size='sm' pointer={true} />
                        </SendButton>
                    </ButtonContainer>
                </StyledForm>
                <ContactColumn width={width}>
                    <ContactCard />
                </ContactColumn>
            </FormWrapper>
        </Container>
    )
}

export default Contact;