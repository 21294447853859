// import About from "./components/About";
import Contact from "./components/Contact";
import Cookie from "./components/Cookie";
import Disclaimer from "./components/Disclaimer";
import Gallerij from "./components/Gallerij";
import Home from "./components/Home";
import Privacy from "./components/Privacy";

const routes = [
    {
        url: '/',
        title: 'Home',
        component: Home,
        hidden: false
        
    },
    {
        url: '/gallerij',
        title: 'Gallerij',
        component: Gallerij,
        hidden: false
    },
    {
        url: '/contact',
        title: 'Contact',
        component: Contact,
        hidden: false
    },
    {
        url: '/privacy',
        title: 'Privacy',
        component: Privacy,
        hidden: true
    },
    {
        url: '/cookie',
        title: 'Cookie',
        component: Cookie,
        hidden: true
    },
    {
        url: '/disclaimer',
        title: 'Disclaimer',
        component: Disclaimer,
        hidden: true
    },
];

export default routes;