import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import Container from './Container';

const StyledContainer = styled(Container)`
    margin-top: ${p => p.marginTop}px;
    
    .image-gallery-content .image-gallery-slide .image-gallery-image {
        max-height: calc(100vh - ${p => p.isFullScreen ? 180 : 380}px);
    }
`;

const Gallerij = () => {
    const [images, setImages] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const importAll = (r) => {
        r.keys().forEach((item, index) => {
            setImages(state => [
                ...state,
                {
                    original: r(item).default,
                    thumbnail: r(item).default,
                    originalClass: 'home-gallery-image'
                }
            ]);
        });
    }

    useEffect(() => {
        importAll(require.context('../images/portfolio', false, /\.(png|jpe?g|svg)$/))
    }, [])

    return (
        <StyledContainer marginTop={50} isFullScreen={isFullScreen}>
            <ImageGallery items={images} onScreenChange={setIsFullScreen} />
        </StyledContainer>
    )
}
export default Gallerij;